<template>
  <div>
    <!-- Title. -->
    <content-title :subtitle="false">
      <template slot="title"> {{ $t('patientPortal.appointments.upcoming') }} </template>
    </content-title>
    <div class="mb-6">
      <div
        class="no-appointments-container d-flex justify-center align-center"
        v-if="loading || !upcomingAppointments.length"
      >
        <v-progress-circular indeterminate v-if="loading" color="primary" />
        <div v-else-if="!upcomingAppointments.length">
          {{ $t('patientPortal.appointments.noUpcoming') }}
        </div>
      </div>
      <div v-if="!loading">
        <appointment-item
          v-for="appointment in upcomingAppointments"
          :key="appointment.id"
          :appointment="appointment"
          upcoming
        />
      </div>
    </div>
    <content-title :subtitle="false">
      <template slot="title"> {{ $t('patientPortal.appointments.past') }} </template>
    </content-title>
    <div class="mb-6">
      <div
        class="no-appointments-container d-flex justify-center align-center"
        v-if="loading || !pastAppointments.length"
      >
        <v-progress-circular indeterminate v-if="loading" color="primary" />
        <div v-else-if="!pastAppointments.length">
          {{ $t('patientPortal.appointments.noPast') }}
        </div>
      </div>
      <div v-if="!loading">
        <appointment-item
          v-for="appointment in pastAppointments"
          :key="appointment.id"
          :appointment="appointment"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.no-appointments-container {
  height: 100px;
  border-radius: 16px;
}
</style>
<script>
import ContentTitle from '../../components/ContentTitle.vue';
import AppointmentItem from '../../components/PatientPortal/AppointmentItem.vue';

export default {
  name: 'Appointments',
  components: {
    AppointmentItem,
    ContentTitle,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    pastAppointments() {
      return this.$store.getters['patientPortal/pastAppointments'];
    },
    upcomingAppointments() {
      return this.$store.getters['patientPortal/upcomingAppointments'];
    },
  },
  methods: {
    async getAllAppointments() {
      this.loading = true;
      await this.$store.dispatch('patientPortal/fetchPastAppointments');
      await this.$store.dispatch('patientPortal/fetchUpcomingAppointments');
      this.loading = false;
    },
  },
  mounted() {
    this.getAllAppointments();
  },
};
</script>
