<template>
  <div
    align-top
    dense
    class="mb-4 py-3 py-sm-4 appointment-item upcoming px-3 px-sm-4 mx-n4 mx-sm-0"
    @click.prevent
  >
    <v-dialog v-model="cancelConfirm" max-width="min(90vw, 500px)">
      <v-card>
        <v-card-title>Cancel appointment?</v-card-title>
        <v-card-text
          >Your appointment on
          <span class="font-weight-bold">{{ getAppointmentDate(appointment.startTime) }}</span>

          will be cancelled. This cannot be undone.</v-card-text
        >
        <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="cancelConfirm = false">{{ $t('main.back') }}</v-btn>
          <v-btn color="error" @click="cancelAppointment">{{ $t('main.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resendConfirm" max-width="min(90vw, 500px)">
      <v-card>
        <v-card-title>Resend confirmation email?</v-card-title>
        <v-card-text> You will receive an email with details about your appointment.</v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="resendConfirm = false">{{ $t('main.back') }}</v-btn>
          <v-btn color="success" @click="resendAppointmentLink">{{ $t('main.send') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="appointment-content d-flex justify-space-between no-wrap">
      <div class="flex-shrink-1" style="min-width: 0">
        <div class="font-main truncate-text">
          Medical assessment
          <span v-if="appointment.appointmentType"
            >({{ $t(`patientPortal.appointments.type.${appointment.appointmentType}`) }})</span
          >
        </div>
        <div class="d-flex flex-wrap appointment-info">
          <div class="d-flex mr-2 w-100" v-if="appointment.primaryComplaint">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-on="on" v-bind="attrs">mdi-clipboard-text-outline</v-icon>
              </template>
              <span>{{ $t('patientPortal.appointments.primaryComplaint') }}</span>
            </v-tooltip>
            <div :class="{ linethrough: isCancelled }">
              <span>{{ appointment.primaryComplaint }}</span>
            </div>
          </div>
          <div class="d-flex mr-2 w-100">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" :color="isCancelled ? '' : 'primary'" v-on="on" v-bind="attrs"
                  >mdi-clock-outline</v-icon
                >
              </template>
              <span>{{ $t('patientPortal.appointments.time') }}</span>
            </v-tooltip>
            <div :class="{ linethrough: isCancelled }">
              {{ getAppointmentDate(appointment.startTime) }}
            </div>
          </div>
          <div class="d-flex w-100" v-if="appointment.location && appointment.location.name">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" :color="isCancelled ? '' : 'error'" v-on="on" v-bind="attrs"
                  >mdi-map-marker</v-icon
                >
              </template>
              <span>{{ $t('patientPortal.appointments.location') }}</span>
            </v-tooltip>
            <div :class="{ linethrough: isCancelled }">
              {{ appointment.location.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="options-container d-flex flex-column nowrap pt-sm-1">
        <v-btn
          rounded
          color="primary"
          style="padding-bottom: 1px"
          small
          v-if="
            $vuetify.breakpoint.smAndUp &&
            appointment.appointmentType === APPOINTMENT_TYPES.video &&
            upcoming &&
            !isCancelled
          "
          @click="joinMeeting"
          >{{ $t('patientPortal.appointments.join') }}</v-btn
        >
        <div
          class="d-flex"
          :class="{
            'flex-column justify-center': $vuetify.breakpoint.xsOnly,
            'justify-end': $vuetify.breakpoint.smAndUp,
          }"
        >
          <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown" v-if="upcoming && !isCancelled">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                  :color="resent ? 'success' : ''"
                  icon
                  @click="resendConfirm = true"
                  :loading="resending"
                  ><v-icon>{{
                    resent ? 'mdi-email-check-outline' : 'mdi-email-sync-outline'
                  }}</v-icon></v-btn
                >
              </div>
            </template>
            <span>
              {{ $t('patientPortal.appointments.resendEmail') }}
            </span>
          </v-tooltip>

          <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                  color="error"
                  icon
                  @click="cancelConfirm = true"
                  v-if="canCancel && !isCancelled"
                  :loading="cancelling"
                  ><v-icon>mdi-calendar-remove</v-icon></v-btn
                >
              </div>
            </template>
            <span>
              {{ $t('patientPortal.appointments.cancelAppointment') }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <v-btn
      block
      color="primary"
      rounded
      class="mb-n1 mt-2"
      v-if="
        $vuetify.breakpoint.xsOnly &&
        appointment.appointmentType === APPOINTMENT_TYPES.video &&
        upcoming &&
        !isCancelled
      "
      @click="joinMeeting"
      >{{ $t('patientPortal.appointments.join') }}</v-btn
    >
  </div>
</template>
<style lang="scss" scoped>
@import '../../styles/utility.scss';
.linethrough {
  text-decoration: line-through !important;
}
.appointment-item {
  border: 1px solid rgba(0, 93, 223, 0.1);
  background-color: white;
  max-width: 600px;
  border-radius: 16px;
  position: relative;

  .status-floating__container {
    position: absolute;
    top: 0em;
    font-size: 0.6em;
    right: $spacer * 4;
    font-weight: bold;
  }

  .appointment-employee__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    .appointment-employee__avatar--frame {
      border-radius: 50%;
      width: 50px;
      aspect-ratio: 1/1;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: $pretty-shadow;
      background-color: white;
      overflow: hidden;

      background-size: contain;
      background-position: center;
      background-size: 70%;
      ::v-deep .v-img {
        height: 100%;
        width: 100%;
      }
      .fallback-avatar {
        ::v-deep .v-image__image {
          background-size: 85% !important;
        }
      }
    }
  }
  .appointment-content {
    width: 100%;
    .appointment-info {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.7em;
      @include sm {
        font-size: 0.9em;
      }
    }
  }
}
</style>

<script>
import {
  TWELVE_HOUR_TIME_FORMAT,
  PRETTY_SMALL_DATE_FORMAT_WITH_YEAR,
} from '../../constants/moment';
import { APPOINTMENT_TYPES } from '../../constants/appointment';
import RESEND_LINK from '../../graphql/Mutation/SendAppointmentMeetLinkEmail.gql';

export default {
  props: {
    appointment: Object,
    upcoming: Boolean,
  },
  data() {
    return {
      APPOINTMENT_TYPES,
      cancelConfirm: false,
      cancelling: false,
      resendConfirm: false,
      resending: false,
      resent: false,
    };
  },
  computed: {
    canCancel() {
      const now = this.$store.getters.toTimezone();
      const appointmentStart = this.$store.getters.toTimezone(this.appointment.startTime);
      return appointmentStart.diff(now, 'hours') > 24;
    },
    isCancelled() {
      return this.appointment.status === 'cancel';
    },
  },
  methods: {
    async cancelAppointment() {
      this.cancelConfirm = false;
      this.cancelling = true;
      await this.$store.dispatch('patientPortal/cancelAppointment', this.appointment.id);
      this.cancelling = false;
    },
    getAppointmentDate(startTime) {
      const date = this.$store.getters
        .toTimezone(startTime)
        .format(PRETTY_SMALL_DATE_FORMAT_WITH_YEAR);

      const time = `${this.$store.getters.toTimezone(startTime).format(TWELVE_HOUR_TIME_FORMAT)}`;

      return `${date} ${time}`;
    },
    joinMeeting() {
      const { sentryMeetLink } = this.appointment;
      window.open(sentryMeetLink, '_blank');
    },
    async resendAppointmentLink() {
      this.resendConfirm = false;
      this.resending = true;

      try {
        await this.$apollo.mutate({
          mutation: RESEND_LINK,
          variables: {
            appointmentId: this.appointment.id,
            providerId: this.$store.getters['patientPortal/providerId'],
          },
        });
        this.resent = true;
      } catch {
        console.error('error sending meet link');
      }
      this.resending = false;
    },
  },
};
</script>
